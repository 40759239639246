<script>
import { ref } from 'vue'
import BaseInputAction from '/~/components/base/input-action/input-action.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useUser } from '/~/composables/user'

export default {
  name: 'profile-add-cash-coupons',
  components: {
    BaseInputAction,
    BaseLoader,
  },
  setup() {
    const { depositDetails, fetchDepositDetails, applyCouponCode } = useUser()
    const { isDarkThemeForEwallet } = useCms()

    const loading = ref(true)

    fetchDepositDetails().then(() => {
      loading.value = false
    })

    return {
      loading,
      depositDetails,
      applyCouponCode,
      isDarkThemeForEwallet,
    }
  },
  data() {
    return {
      code: '',
      processing: false,
    }
  },
  methods: {
    applyCode() {
      this.processing = true

      this.applyCouponCode({
        ewalletReference: this.depositDetails.reference,
        coupon: this.code,
      })
        .then(() => {
          this.code = ''
          this.$router.push({
            hash: '#profile-home',
          })
        })
        .catch(() => {
          this.code = ''
        })
        .finally(() => {
          this.processing = false
        })
    },
  },
}
</script>

<template>
  <div>
    <div v-if="loading" class="mt-2.5 flex items-center justify-center">
      <base-loader fullwidth size="xl" />
    </div>
    <template v-else>
      <base-input-action
        v-model="code"
        btn-text="Apply"
        label="Redemption Code"
        :disabled="!code"
        :loading="processing"
        :action="applyCode"
        :look="isDarkThemeForEwallet ? 'ewallet-v2-light' : 'ewallet-v2'"
      />
      <div class="mt-4 text-sm text-eonx-neutral-600">
        * Funds will be available instantly
      </div>
    </template>
  </div>
</template>
