<script>
import get from 'lodash-es/get'
import { nextTick } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms/use-cms'
import { useExtensions } from '/~/composables/extensions'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import AddCashCoupons from './components/add-cash-coupons.vue'
import AddCashDirectDeposits from './components/add-cash-direct-deposits.vue'
import AddCashPaymentMethods from './components/add-cash-payment-methods.vue'

export default {
  name: 'profile-add-cash-v2',
  components: {
    BaseTabs,
    BaseTab,
    BaseAsidePage,
    AddCashPaymentMethods,
    AddCashCoupons,
    AddCashDirectDeposits,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const { getManifestByName } = useExtensions()
    const { isMethodAvailable } = usePaymentMethods()
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()

    return {
      getManifestByName,
      isMethodAvailable,
      isDarkThemeForEwallet,
      ewalletLabels,
      PaymentMethodType,
    }
  },
  data() {
    return {
      tab: '',
    }
  },
  computed: {
    addCashConfig() {
      const module = this.getManifestByName('addcash')

      return get(module, 'config.topUpSources', {})
    },
    cashCouponsEnabled() {
      return get(this.addCashConfig, 'cashCoupons.enabled', false)
    },
    directDepositsEnabled() {
      return get(this.addCashConfig, 'directDeposits.enabled', false)
    },
    paymentMethodsEnabled() {
      return get(this.addCashConfig, 'paymentMethods.enabled', false)
    },
    paymentMethodsTabName() {
      const title = []

      if (this.availableMethods(this.PaymentMethodType.creditCard)) {
        title.push('Debit Card')
      }

      if (this.availableMethods(this.PaymentMethodType.bankAccount)) {
        title.push('Bank Account')
      }

      return title.length ? title.join(' / ') : 'Payment Method'
    },
  },
  created() {
    if (this.paymentMethodsEnabled) {
      this.tab = 'paymentMethods'
    } else if (this.directDepositsEnabled) {
      this.tab = 'directDeposit'
    } else if (this.cashCouponsEnabled) {
      this.tab = 'code'
    }
  },
  methods: {
    availableMethods(type) {
      return this.isMethodAvailable(type, FlowType.topUpOrder)
    },
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    :title="`Add Cash to ${ewalletLabels.ewallet}`"
    no-padding
  >
    <base-tabs
      v-model="tab"
      class="h-full"
      :look="isDarkThemeForEwallet ? 'v3-light' : 'v3'"
    >
      <base-tab
        v-if="paymentMethodsEnabled"
        :name="paymentMethodsTabName"
        value="paymentMethods"
        keep-alive
      >
        <add-cash-payment-methods class="px-5" />
      </base-tab>
      <base-tab
        v-if="directDepositsEnabled"
        name="Direct Deposit"
        value="directDeposit"
        keep-alive
      >
        <add-cash-direct-deposits class="px-5" />
      </base-tab>
      <base-tab
        v-if="cashCouponsEnabled"
        name="Redemption Code"
        value="code"
        keep-alive
      >
        <add-cash-coupons class="px-5" />
      </base-tab>
    </base-tabs>
  </base-aside-page>
</template>
