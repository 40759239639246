<script>
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import { useUser } from '/~/composables/user'

export default {
  name: 'profile-add-cash-direct-deposits',
  components: {
    BasePlaceholder,
  },
  setup() {
    const { depositDetails, fetchDepositDetails } = useUser()
    const { accountPresentation } = useBankAccountForm()
    const { ewalletLabels } = useCms()

    fetchDepositDetails()

    return {
      depositDetails,
      accountPresentation,
      ewalletLabels,
    }
  },
  data() {
    return {
      detailsSchema: {
        bankName: 'To',
        reference: 'Use this Reference',
        ...(this.accountPresentation.bsb && { bankBsb: 'BSB' }),
        bankNumber: 'Account Number',
      },
    }
  },
}
</script>

<template>
  <div>
    <div class="mb-6 pt-[30px] text-xl font-bold">
      Add Cash from Internet Banking
    </div>
    <p class="mb-6">
      Simply deposit from your Internet Banking to
      {{ ewalletLabels.yourEwallet }} account using the Reference number below.
      Your funds will automatically appear in
      {{ ewalletLabels.yourEwallet }} within 3 business days.
    </p>
    <div class="mb-[30px] rounded-md border p-6 no-underline last:mb-0">
      <table class="-my-[15px] w-full">
        <tbody>
          <tr v-for="(value, key) in detailsSchema" :key="key">
            <td class="whitespace-no-wrap py-2.5 pr-6 text-eonx-neutral-600">
              {{ value }}
            </td>
            <td
              class="w-full font-bold"
              :class="key === 'code' && 'text-fg-success'"
            >
              <base-placeholder
                v-if="!depositDetails"
                :rows="1"
                type="text"
                height="24"
              />
              <template v-else>
                {{ depositDetails[key] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-sm text-eonx-neutral-600">
      * Please make sure to use the Reference as the description when making
      your deposit or we will not be able to allocate your funds.
    </div>
  </div>
</template>
